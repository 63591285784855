import {loginFlow} from '@hconnect/adminconsole/src/App.store'
import {
  AuthRedirectQueryParams,
  BrowserBackendSelector,
  getDefaultDFBackends,
  Product
} from '@hconnect/apiclient'
import queryString from 'query-string'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {Wrapper} from './Components/Wrapper/Wrapper'
import {useGlobalState, validateSession} from './hooks/useGlobalState'
import {ssoLogout} from './Pages/Account/Account'
import {InvalidSession} from './Pages/InvalidSession'
import {RedirectUrls} from './RedirectUrls'
import {Router} from './Router'
import {getCRMUrl} from './utils'

// eslint-disable-next-line complexity
export const App = () => {
  const {globalState} = useGlobalState()
  const {t} = useTranslation()

  const isValidSession = validateSession(globalState)

  useEffect(() => {
    document.title = t('authenticator.title')
  })

  // Handle app-initiated SSO logout
  useEffect(() => {
    const queryParams = queryString.parse(window.location.search)
    if (queryParams.logoutId) {
      void ssoLogout(queryParams.logoutId as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const queryParams = queryString.parse(window.location.search) as AuthRedirectQueryParams

  if (!isValidSession && globalState.clientId !== Product.CRM && !queryParams.code) {
    const referrerURL = window.document.referrer
    if (
      referrerURL &&
      (window.location.pathname === '' || window.location.pathname === '/') &&
      window.location.search === ''
    ) {
      window.location.href = referrerURL
      return null
    }

    return (
      <Wrapper>
        {process.env.NODE_ENV === 'development' ? <RedirectUrls /> : <InvalidSession />}
      </Wrapper>
    )
  }
  if (queryParams.logoutId) {
    return null
  }

  if (
    globalState.clientId !== Product.CRM &&
    !globalState.isPopupLogin &&
    !globalState.identityServerReturnUrl &&
    globalState.redirectUrl &&
    (window.location.pathname === '' || window.location.pathname === '/') &&
    globalState.redirectUrl.origin !== 'http://no-redirect-url.de'
  ) {
    window.location.href = globalState.redirectUrl.toString()
  }

  if (
    (globalState.clientId === Product.CRM || globalState.isPopupLogin === true) &&
    !globalState.identityServerReturnUrl
  ) {
    void loginFlow.startLoginProcess(globalState.clientId)
  }

  if (queryParams.code) {
    const getToken = async () => {
      const backendSelector = new BrowserBackendSelector(getDefaultDFBackends())
      const selectedBackend = backendSelector.getSelectedBackend()
      const crmUrl = getCRMUrl()
      const cscUrl = selectedBackend.MHC_URL
      const clientId = globalState.clientId || Product.CRM

      const loginResponse = await loginFlow.getLoginState(clientId)

      if (loginResponse.loggedIn && 'token' in loginResponse) {
        ;(crmUrl || cscUrl) &&
          window.opener.postMessage(loginResponse.token, clientId === Product.CRM ? crmUrl : cscUrl)
      }

      if (!loginResponse.loggedIn) {
        ;(crmUrl || cscUrl) &&
          window.opener.postMessage(
            'Error: Account not found',
            clientId === Product.CRM ? crmUrl : cscUrl
          )
      }
    }
    void getToken()
  }

  return (
    <Wrapper>
      <Router />
    </Wrapper>
  )
}
